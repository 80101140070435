import React, { useContext, useRef, useEffect, useState } from 'react'
import styles from './style/CarlevelResultDashboad.module.scss'
import downloadBtn from '../img/download.png'
import { useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeAuthRequest } from './context/utils'
import Cookies from 'js-cookie'
import lang from './language/CarlevelResultDashboard.json'
import config from '../config/config.json'

const RejectedPartLevelDashboard = () => {
    const {urlLink, editClients, rejectedPDFClients} = config
    const {photowiseDetails, language, showCarRemarkModal, setShowCarRemarkModal, carRemark, setCarRemark, setPhotowiseDetails, setPhotowiseLoading} = useContext(AppContext)

    const { id } = useParams()

    const downloadPDF = async() => {
        if(!rejectedPDFClients.includes(Cookies.get("master"))) return
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/report`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}download/report`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), language})
        
        if(res.ok){
            const data = await res.json()
            window.open(data.url, "_blank")
        }
    }

    const downloadImages = async() => {
        if(Cookies.get("master") === "sompo_maruti" || Cookies.get("master") === "sompo_maruti_uat" || Cookies.get("master") === "client_989"){
            const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/images`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}download/images`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original"})
            
            if(res.ok){
                const data = await res.json()
                if (data.url == null){
                    return
                }else{
                    window.open(data.url, "_blank")
                }
            }
        }
    }

    const downloadJSON = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/json`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}download/json`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType')})
        
        if(res.ok){
            const data = await res.json()
            if(data.status === "failed") return alert(data.url)
            window.open(data.url, "_blank")
        }
    }

    const getInspectionDetails = async() => {
        setPhotowiseLoading(true)
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}photowise-details-rejected`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}photowise-details-rejected`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType') })
        const data = await res.json()
        if(res.ok){
          setPhotowiseDetails(data)
          setPhotowiseLoading(false)
        }
        
    }

    const sendRemarkData = async () => {
    
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}remark/update`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}remark/update`, {inspection_id: id, remark: carRemark, client_id: Cookies.get('client'), page_no: "", client_type: Cookies.get('clientType')})
        setCarRemark("")
    
        if(res.ok){
          setShowCarRemarkModal(false)
          getInspectionDetails()
        }
    
    }

    const localTime = (time) => {
        let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        let [datePart, timePart] = time.split(", ")
        let [day, monthName, year] = datePart.split(" ")
        let [timeValue, ampm] = timePart.split(" ")
        
        let [hour, minute] = timeValue.split(":").map(Number)
        if (ampm === "PM" && hour !== 12) hour += 12
        if (ampm === "AM" && hour === 12) hour = 0
    
        let month = Months.indexOf(monthName)
    
        let utcDate = new Date(Date.UTC(year, month, day, hour, minute))
    
        let local = new Date(utcDate)
    
        let localDay = local.getDate()
        let localMonth = Months[local.getMonth()]
        let localYear = local.getFullYear()
        let localHour = local.getHours() % 12 || 12
        let localMinute = local.getMinutes().toString().padStart(2, '0')
        let localAmPm = local.getHours() >= 12 ? "PM" : "AM"
    
        return `${localDay} ${localMonth} ${localYear}, ${localHour}:${localMinute} ${localAmPm}`
    }

    return (
    <div className={styles.resultDashboard}>
        <div className={styles.dashBoardContent}>
            <div className={styles.dashboardHeader}>
                <p className={styles.dashboardTitle}>{lang["Inspection Results"][language]}</p>
                <p className={styles.dashboardSub}>{lang["Inspection ID"][language]} {id} {localTime(photowiseDetails["batch_hit_time"])}</p>
                <div>
                    <button onClick={()=>downloadPDF()} style={rejectedPDFClients.includes(Cookies.get("master")) ? {} : {backgroundColor : "#535350"}} className={styles.downloadBtn}>{lang["Download PDF"][language]} <img src={downloadBtn} alt="Download" /></button>
                    {!Cookies.get("isMahindra") && <button onClick={()=>{}} style={{backgroundColor : "#535350"}} className={styles.downloadBtn}>{lang["Download JSON"][language]} <img src={downloadBtn} alt="Download" /></button>}
                    {!Cookies.get("isMahindra") && <button onClick={()=>{downloadImages()}} style={(Cookies.get("master") === "sompo_maruti" || Cookies.get("master") === "sompo_maruti_uat" || Cookies.get("master") === "client_989") ? {backgroundColor : "#157AFF"} : {backgroundColor : "#535350"}} className={styles.downloadBtn}>{lang["Download Images"][language]} <img src={downloadBtn} alt="Download" /></button>}
                </div>
            </div>
        </div>
        <hr/>
        <div className={styles.dashboardTable} >
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Type of inspection"][language]}</p>
                    {photowiseDetails["web_app_case"] === "0"? <p>{lang["API/Portal Upload"][language]}</p>: <p>Super App</p>}
                </div>
            </div>
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Result Status"][language]}</p>
                    <p>{lang["Rejected"][language]}</p>
                </div>
            </div>
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Reason"][language]}</p>
                    <p>{Object.keys(lang).includes(photowiseDetails["rejection_res"]) ? lang[photowiseDetails["rejection_res"]][language]: photowiseDetails["rejection_res"]}</p>
                </div>
            </div>
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Remark"][language]}</p>
                    <div className={styles.remarkContainer}>
                        <p className={styles.remarkPara}>{photowiseDetails.remarks}</p>
                        <button onClick={()=>{
                            setShowCarRemarkModal(true)
                            if(photowiseDetails.remarks) setCarRemark(photowiseDetails.remarks)
                        }} className={styles.submitBtn}>{lang["Add"][language]}</button>
                    </div>
                </div>
            </div>
        </div>
        {showCarRemarkModal && <div className={styles.remarkModalContainer}>
            <div className={styles.remarkModal}>
                <h3>{lang["Add Remark"][language]}</h3>
                <textarea
                    value = {carRemark}
                    onChange={(e)=> setCarRemark(e.target.value)}
                    className={styles.remarkInput}
                >
                </textarea>
                <div className={styles.remarkModalButtonContainer}>
                    <div onClick={()=>sendRemarkData()}>{lang["Save"][language]}</div>
                    <div onClick={()=>{
                    setShowCarRemarkModal(false)
                    setCarRemark("")
                    }}>{lang["Close"][language]}</div>
                </div>
            </div>
        </div>}
    </div>  )
}

export default RejectedPartLevelDashboard