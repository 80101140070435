import React,{ useContext, useState, useEffect } from 'react'
import styles from './style/ClaimPartLevelResultDashboard.module.scss'
import downloadBtn from '../img/download.png'
import { AppContext } from './context';
import { useNavigate, useParams } from 'react-router-dom';
import { makeAuthRequest } from './context/utils';
import Cookies from 'js-cookie';
import lang from './language/ClaimPartLevelResultDashboard.json'
import closeImage from "../img/modalClose.png";
import partDamage from './language/partDamage.json'
import config from '../config/config.json'
import nextBtn from '../img/preInspection/blueNext.png'
import greyNextBtn from '../img/preInspection/greyNext.png'
import DamageTypeModal from './DamageTypeModal';


const ClaimPartLevelResultDashboard = () => {
    const {urlLink, stGobainComp} = config

    const {photowiseDetails, open, setOpen, language, setClickedPart, clientType, isGTEstimateActive, openGTResponseModal,
            setGTResponseMessage, setOpenGTResponseModal, GTResponseMessage, allowDamageModal, setSelectedImage} = useContext(AppContext)

    const { id } = useParams()
    const navigate = useNavigate()

    const [isResponseDownloading, setisResponseDownloading] = useState(false)
    const [stGobainId, setStGobainId] = useState("")

    const onOpenModal = (value) => {
        if(!allowDamageModal) return
        setOpen(true)
    }
    const downloadPDF = async() => {
        const res = await makeAuthRequest(`${urlLink}download/report`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), language})
        
        if(res.ok){
            const data = await res.json()
            window.open(data.url, "_blank")
        }
    }

    const downloadImages = async() => {
        const res = await makeAuthRequest(`${urlLink}download/images`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original"})
        
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const downloadJSON = async() => {
        const res = await makeAuthRequest(`${urlLink}download/json`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType')})
        
        if(res.ok){
            const data = await res.json()
            if(data.status === "failed") return alert(data.url)
            window.open(data.url, "_blank")
        }
    }

    const getGTEstimate = async() => {
        if(!isGTEstimateActive) return
        window.open(`${window.location.origin}/estimate/${id}`, "_blank")
    }

    const dowloadGTReport = async() => {
        if(!isGTEstimateActive) return
        const res = await makeAuthRequest(`${urlLink}download/pdf`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.file_url) return window.open(data.file_url, "_blank")
        }
    }

    const downloadDomusReport = async() => {
        if(photowiseDetails["unified_json"]["preInspection"]["damagedParts"].length === 0){
            setGTResponseMessage(lang["No damage found"][language])
            setOpenGTResponseModal(true)
            return
        }
        const res = await makeAuthRequest(`${urlLink}/domus/download/report`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.status === "success"){
                return window.open(data.url, "_blank")
            }else{
                setGTResponseMessage(data.url)
                setOpenGTResponseModal(true)
            }
        }
    }

    const downloadPersonaImages = async() => {
        if(!stGobainId){
            return alert("Please select an insurer")
        }
        const res = await makeAuthRequest(`${urlLink}download/images/persona`, {client_id: Cookies.get('master'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original", insurer_name: stGobainId})
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const getGTResponse = async() => {
        if(isResponseDownloading) return
        setisResponseDownloading(true)
        setTimeout(() => {
            setisResponseDownloading(false)
        }, 8000);
        const res = await makeAuthRequest(`${urlLink}get_gtMotive_response`, {inspection_id: id, client_id: Cookies.get('client'), client_type: Cookies.get('clientType')})
        const data = await res.json()
        if(res.ok){
            if(data.status === "Success"){
                const blob = new Blob([data.response], { type: 'text/plain' })
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', "response.txt")
                document.body.appendChild(link)
                link.click()
                link.remove()
            }
        }
    }

    const convertPart = (part) => {
        let nameFound = false
        for(let i = 0; i <partDamage.length; i++){
            if(partDamage[i].English === part){
                if(Object.keys(partDamage[i]).includes(language)){
                    nameFound = true
                    return partDamage[i][language]
                }
            }
        }
        if(!nameFound) return part
    }

    const convertDamage = (damages) => {
        let temp = []
        let nameFound = false
        if(Array.isArray(damages)){
            damages = damages.join(', ')
        }
        if(!damages.split('').includes(",")){
            for(let i = 0; i <partDamage.length; i++){
                if(partDamage[i].English === damages){
                    if(Object.keys(partDamage[i]).includes(language)){
                        nameFound = true
                        return partDamage[i][language]
                    }
                }
            }
            if(!nameFound) return damages
        }else{
            let damageList = damages.split(', ')
            for(let i = 0; i < damageList.length; i++){
                nameFound = false
                for(let j = 0; j <partDamage.length; j++){
                    if(partDamage[j].English === damageList[i]){
                        if(Object.keys(partDamage[j]).includes(language)){
                            nameFound = true
                            temp.push(partDamage[j][language])
                            break
                        }
                    }
                }
                if(!nameFound) temp.push(damageList[i])
            }
            return temp.join(', ')
        }
    }

    const checkImage = (partName) => {

        let partExists = photowiseDetails["relevant_image_section"].some(obj => 
            Array.isArray(obj.boundingBoxInformation) && 
            obj.boundingBoxInformation.some(info => info.partComponent === partName)
        )

        if(partExists){
            let index = photowiseDetails["relevant_image_section"].findIndex(obj => 
                Array.isArray(obj.boundingBoxInformation) && 
                obj.boundingBoxInformation.some(info => info.partComponent === partName)
            )
            setSelectedImage(photowiseDetails["relevant_image_section"][index])
        }
    }

    const localTime = (time) => {
        let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        let [datePart, timePart] = time.split(", ")
        let [day, monthName, year] = datePart.split(" ")
        let [timeValue, ampm] = timePart.split(" ")
        
        let [hour, minute] = timeValue.split(":").map(Number)
        if (ampm === "PM" && hour !== 12) hour += 12
        if (ampm === "AM" && hour === 12) hour = 0
    
        let month = Months.indexOf(monthName)
    
        let utcDate = new Date(Date.UTC(year, month, day, hour, minute))
    
        let local = new Date(utcDate)
    
        let localDay = local.getDate()
        let localMonth = Months[local.getMonth()]
        let localYear = local.getFullYear()
        let localHour = local.getHours() % 12 || 12
        let localMinute = local.getMinutes().toString().padStart(2, '0')
        let localAmPm = local.getHours() >= 12 ? "PM" : "AM"
    
        return `${localDay} ${localMonth} ${localYear}, ${localHour}:${localMinute} ${localAmPm}`
    }

    return (
    <div className={styles.partLevelDashboard}>
        <div className={styles.partLevelDashboardContent}>
            <div className={styles.partLevelDashboardHeader}>
                <p className={styles.dashboardTitle}>{lang["Inspection Results"][language]}</p>
                <p className={styles.dashboardSub}>{lang["Inspection ID"][language]} {id} {localTime(photowiseDetails["batch_hit_time"])}</p>
                <div>
                    <button onClick={()=>downloadPDF()} className={styles.downloadBtn}>{lang["Download PDF"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>downloadJSON()} className={styles.downloadBtn}>{lang["Download JSON"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>downloadImages()} className={styles.downloadBtn}>{lang["Download Images"][language]} <img src={downloadBtn} alt="Download" /></button>
                </div>
                { clientType === "gt_motive" && <div>
                    <button onClick={()=>getGTEstimate()} style={{backgroundColor : isGTEstimateActive ? "#157AFF" : "#535350"}} className={styles.downloadBtn}> {lang["GT Estimate"][language]} </button>
                    <button onClick={()=>dowloadGTReport()} style={{backgroundColor : isGTEstimateActive ? "#157AFF" : "#535350"}} className={styles.downloadBtn}> {lang["GT Report Download"][language]} <img src={downloadBtn} alt="Download" /></button>
                    <button onClick={()=>getGTResponse()} className={styles.downloadBtn}> {lang["Check GT Response"][language]} </button>
                </div>}
                { clientType === "domus" && <div>
                    <button onClick={()=>downloadDomusReport()} className={styles.downloadBtn}> {lang["Domus Report Download"][language]} <img src={downloadBtn} alt="Download" /></button>
                </div>}
                {Cookies.get('master') === "client_989" && <div style={{marginTop: "20px"}}>
                    <select value={stGobainId} onChange={(e)=>setStGobainId(e.target.value)}>
                        <option value= "">Select insurer</option>
                        {stGobainComp.map((item, index) => {
                        return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                    <button style={{margin: "0px"}} onClick={()=>downloadPersonaImages()} className={styles.downloadBtn}> {lang["Dowload Selected Images"][language]} </button>
                </div>}
            </div>
            <div className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <td>{lang["Part"][language]}</td>
                            <td>{lang["Damage Type"][language]}</td>
                            <td>{lang["Confidence Score"][language]}</td>
                            <td>{lang["Severity"][language]}</td>
                            {Cookies.get('master') !== "client_507" && <td>{lang["Operation"][language]}</td>}
                            {Cookies.get('master') !== "client_507" && <td>{lang["Labour Hrs."][language]}</td>}
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {photowiseDetails["unified_json"]["preInspection"]["damagedParts"].map((v,i) => <tr key={i}>
                            <td style={{cursor: "pointer", color: "#157aff"}} onClick={()=>checkImage(v.partName)}>{convertPart(v.partName)}</td>
                            <td>{convertDamage(v.listOfDamages)}</td>
                            <td>{`${Math.floor(v.confidenceScore * 100)}%`}</td>
                            <td>{`${Math.floor(v.damageSeverityScore * 100)}%`}</td>
                            {Cookies.get('master') !== "client_507" && <td>{(Object.keys(lang).includes(v.laborOperation) && Object.keys(lang[v.laborOperation]).includes(language)) ? lang[v.laborOperation][language] : v.laborOperation}</td>}
                            {Cookies.get('master') !== "client_507" && <td>{v.laborRepairUnits}</td>}
                            <td><div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><img style={allowDamageModal ? {cursor: "pointer"} : {cursor: "default"}} onClick={()=>{onOpenModal(i)}} src={allowDamageModal ? nextBtn : greyNextBtn}></img><img style={{cursor: "pointer"}} style={allowDamageModal ? {cursor: "pointer"} : {cursor: "default"}} onClick={()=>{onOpenModal(i)}} src={allowDamageModal ? nextBtn : greyNextBtn}></img></div></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            {open && 
                <div className={styles.hailDamageModal}>
                    <DamageTypeModal />
                </div>}
        </div>
        {openGTResponseModal && 
            <div className={styles.polcyModalContainer}>
                <div style={{display: "flex", justifyContent: "center", height: "100px"}} className={styles.policyModalMain}>
                    <img className={styles.CloseButton} src={closeImage} alt='X' onClick={()=>{
                        setGTResponseMessage("")
                        setOpenGTResponseModal(false)
                    }}/>
                    <h3>{GTResponseMessage}</h3>
                </div>
            </div>
        }
    </div>
  )
}

export default ClaimPartLevelResultDashboard