import React, { useContext, useRef, useEffect, useState } from 'react'
import styles from './style/CarlevelResultDashboad.module.scss'
import downloadBtn from '../img/download.png'
import { useParams } from 'react-router-dom'
import { AppContext } from './context'
import { makeAuthRequest } from './context/utils'
import Cookies from 'js-cookie'
import lang from './language/CarlevelResultDashboard.json'
import modalLoading from '../img/modalLoading.gif'
import tick from '../img/tick.png'
import saveTick from '../img/saveTick.png'
import editImg from '../img/edit.png'
import config from '../config/config.json'

const CarlevelResultDashboard = () => {
    const {urlLink, editClients, stGobainComp, octoClients} = config
    const {photowiseDetails, agentApprove, setAgentApprove, policyNumber, setPolicyNumber, language, showPolicyModal, setShowPolicyModal, policyEmail, setPolicyEmail,
            policyLoading, setPolicyLoading, showApproveModal, setShowApproveModal, showCarRemarkModal, setShowCarRemarkModal, carRemark, setCarRemark, setPhotowiseDetails,
            setPhotowiseLoading, vin, setVin, showVinModal, setShowVinModal, configData, editMode, setEditMode, carLevelEditData, setCarLevelEditData, licensePlate, setLicensePlate,
            make, setMake, model, setModel, odometer, setOdometer, fuelmeter, setFuelMeter, partLevelDisplayData, partLevelEditData, setEditRow, partCheckBox, setPartCheckBox, 
            isGTEstimateActive, editedData, setEditedData} = useContext(AppContext)

    const { id } = useParams()
    const policyRef = useRef()
    const [editLicense, setEditLicense] = useState(false)
    const [editMake, setEditMake] = useState(false)
    const [editModel, setEditModel] = useState(false)
    const [editOdometer, setEditOdometer] = useState(false)
    const [editVin, setEditVin] = useState(false)
    const [editFuelmeter, setEditFuelmeter] = useState(false)
    const [stGobainId, setStGobainId] = useState("")
    const [disapprovedReason, setDisapprovedReason] = useState("")
    const [showDissaproveModal, setShowDissaproveModal] = useState(false)

    const downloadPDF = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/report`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), language}) : await makeAuthRequest(`${urlLink}download/report`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), language})
        
        if(res.ok){
            const data = await res.json()
            window.open(data.url, "_blank")
        }
    }

    const downloadImages = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/images`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), image_type: "original"}) : await makeAuthRequest(`${urlLink}download/images`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original"})
        
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const downloadJSON = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/json`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}download/json`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType')})
        
        if(res.ok){
            const data = await res.json()
            if(data.status === "failed") return alert(data.url)
            window.open(data.url, "_blank")
        }
    }

    const getInspectionDetails = async() => {
        setPhotowiseLoading(true)
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), agent_tl_view: "", agent_tl_id: ""}) : await makeAuthRequest(`${urlLink}photowise-details`, { client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId") })
        const data = await res.json()
        if(res.ok){
          setPhotowiseDetails(data)
          setPhotowiseLoading(false)
        }
        
      }

    const sendApproval = async () => {
        setShowApproveModal(true)
        const res = await makeAuthRequest(`${urlLink}agent/approved`, {client_id: Cookies.get('client'), inspection_id: id, agent_approved: agentApprove, client_type: Cookies.get('clientType'), disapproval_reason: disapprovedReason, case_id: photowiseDetails["unified_json"]["caseId"]})

        if(res.ok){
            setShowDissaproveModal(false)
            setTimeout(()=>setShowApproveModal(false), 1000)
        }
    }

    const sendPolicyApproval = async () => {
        const res = await makeAuthRequest(`${urlLink}issue/policy`, {client_id: Cookies.get('client'), inspection_id: id, issue_policy: "Issued", policy_number: policyNumber, client_type: Cookies.get('clientType'), email: policyEmail})
        const data = await res.json()
        console.log(showPolicyModal)
        if(res.ok){
            // if(!showPolicyModal) return
            if(data.status === "error"){
                setPolicyLoading("error")
                setTimeout(()=>closePolicyModal(), 2000)
                return
            }
            setPolicyLoading("completed")
            setTimeout(()=>closePolicyModal(), 2000)
        }
    }

    const closePolicyModal = () => {
        setShowPolicyModal(false)
        setPolicyEmail("")
        setPolicyLoading("loading")
    }

    const sendRemarkData = async () => {
    
        const res = await makeAuthRequest(`${urlLink}remark/update`, {inspection_id: id, remark: carRemark, client_id: Cookies.get('client'), page_no: "", client_type: Cookies.get('clientType')})
        setCarRemark("")
    
        if(res.ok){
          setShowCarRemarkModal(false)
          getInspectionDetails()
        }
    
    }

    const sendVinData = async () => {
        if(!vin) return
        const res = await makeAuthRequest(`${urlLink}updateVinGT`, {inspection_id: id, vin: vin, client_id: Cookies.get('client'), page_no: "", client_type: Cookies.get('clientType')})
        setVin("")
        setShowVinModal(false)
    
        if(res.ok){
          getInspectionDetails()
        }
    
    }

    const generateNewPdf = async () => {
        const res = await makeAuthRequest(`${urlLink}generatePDF`, {inspection_id: id, client_type: Cookies.get('clientType')})
        window.alert(lang["pdfGenMsg"][language])
    }

    const downloadPersonaImages = async() => {
        if(!stGobainId){
            return alert("Please select an insurer")
        }
        const res = await makeAuthRequest(`${urlLink}download/images/persona`, {client_id: Cookies.get('master'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original", insurer_name: stGobainId})
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const submitEditData = async() => {
        let payload = {
            "inspection_id": id,
            "client_id": Cookies.get('client'),
            "license_plate": carLevelEditData["licensePlate"],
            "make": carLevelEditData["make"],
            "model": carLevelEditData["model"],
            "odometer": carLevelEditData["odometer"],
            "vin": carLevelEditData["vin"],
            "fuel_meter": carLevelEditData["fuelmeter"],
            "cause_of_damage": "",
            "remark": "",
            "damages": partLevelDisplayData,
            "gtMotive": isGTEstimateActive ? "True" : "False",
            "edits": {"data": editedData}
        }

        if(validateData(payload)){
            if(payload["odometer"] !== ""){
                payload["odometer"] = parseFloat(payload["odometer"])
            }
            if(payload["fuel_meter"] !== ""){
                payload["fuel_meter"] = parseFloat(payload["fuel_meter"])
            }
            for (let i = 0; i < payload["damages"].length; i++) {
                payload["damages"].damageSeverityScore = parseFloat(payload["damages"].damageSeverityScore)
                payload["damages"].laborRepairUnits = parseFloat(payload["damages"].laborRepairUnits)
            }
        }else{
            return
        }
        
        console.log(payload)
        const res = await makeAuthRequest(`${urlLink}editInspection`, payload)
        
        if(res.ok){
            const data = await res.json()
            setEditMode(false)
            console.log(data)
        }
    }

    const validateData = (payload) => {

        if(payload["odometer"] !== ""){
            if(!(/^\d+$/.test(payload["odometer"])) || payload["odometer"].length >= 10 || parseFloat(payload["odometer"]) < 0){
                alert(lang["odoErrorMsg"][language])
                return false
            }
        }
        
        if(payload["fuel_meter"] !== ""){
            if(!(/^\d+$/.test(payload["fuel_meter"])) || payload["fuel_meter"].length >= 10 || parseFloat(payload["fuel_meter"]) < 0){
                alert(lang["fuelErrorMsg"][language])
                return false
            }
        }

        if(payload["vin"] !== ""){
            if(payload["vin"].length > 17){
                alert(lang["vinErrorMsg"][language])
                return false
            }
        }

        for (let i = 0; i < payload["damages"].length; i++) {

            if(payload["damages"][i].listOfDamages === ""){
                alert("Damage missing in row" + (i+1))
                return false
            }

            if(payload["damages"][i].damageSeverityScore === ""){
                alert(lang["sevScoreEmptyerror"][language])
                return false
            }
    
            if(payload["damages"][i].laborRepairUnits === ""){
                alert(lang["LbrhoursEmptyError"][language])
                return false
            }

            if(parseFloat(payload["damages"][i].damageSeverityScore) < 0 || parseFloat(payload["damages"][i].damageSeverityScore) > 1 || payload["damages"][i].damageSeverityScore.length >= 5){
                alert(lang["sevScoreError"][language])
                return false
            }

            if(parseFloat(payload["damages"][i].laborRepairUnits) < 0 || payload["damages"][i].laborRepairUnits.length >= 9){
                alert(lang["lbrHourserror"][language])
                return false
            }

        }
        return true
    }

    const disableEditing = () => {
        if(editMode){
            setEditRow(false)
            let temp = partCheckBox
            for (let i = 0; i < partCheckBox.length; i++) {
                if(partCheckBox[i] === "true"){
                    temp[i] = "false"
                }
            }
            setPartCheckBox(temp)
            let answer = confirm(lang["confirmMsg"][language])
            if(answer){
                submitEditData()
            }
            else{
                return
            }
        }else{
            setEditMode(true)
        }
    }

    const updateEditData = (type) => {
        let temp = {
            'licensePlate': carLevelEditData["licensePlate"],
            'make': carLevelEditData["make"],
            'model': carLevelEditData["model"],
            'odometer': carLevelEditData["odometer"],
            'vin': carLevelEditData["vin"],
            'fuelmeter': carLevelEditData["fuelmeter"]
        }
        if(type === "licensePlate"){
            if(carLevelEditData['licensePlate'] != licensePlate){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['licensePlate'] = licensePlate
            setEditLicense(false)
        }
        if(type === "make"){
            if(carLevelEditData['make'] != make){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['make'] = make
            setEditMake(false)
        }
        if(type === "model"){
            if(carLevelEditData['model'] != model){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['model'] = model
            setEditModel(false)
        }
        if(type === "odometer"){
            if(carLevelEditData['odometer'] != odometer){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['odometer'] = odometer
            setEditOdometer(false)
        }
        if(type === "vin"){
            if(carLevelEditData['vin'] != vin){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['vin'] = vin
            setEditVin(false)
        }
        if(type === "fuelmeter"){
            if(carLevelEditData['fuelmeter'] != fuelmeter){
                let temp_obj = editedData
                temp_obj.push(type)
                setEditedData(temp_obj)
            }
            temp['fuelmeter'] = fuelmeter
            setEditFuelmeter(false)
        }
        setCarLevelEditData(temp)
    }

    const severityColor = (value) => {
        if(value == 0) return "green"
        if(value > 0 && value <= 5) return "#b59f26"
        if(value > 5 && value <= 50) return "#FF7F50"
        if(value > 50 && value <= 75) return "red"
        if(value > 75) return "darkred"
    }

    const getRadius = () => {
        return `${565.2 * ((100 - photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])/100)}px`
    }

    const repairColor = (value) => {
        if(value == "No Repair") return "green"
        if(value == "Smart Repair") return "yellow"
        if(value == "Bodyshop Repair") return "orange"
        if(value == "Glass Repair") return "red"
        if(value == "Partially Total Loss") return "red"
        if(value == "Total Loss") return "darkred"
        return "black"
    }

    const localTime = (time) => {
        let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        let [datePart, timePart] = time.split(", ")
        let [day, monthName, year] = datePart.split(" ")
        let [timeValue, ampm] = timePart.split(" ")
        
        let [hour, minute] = timeValue.split(":").map(Number)
        if (ampm === "PM" && hour !== 12) hour += 12
        if (ampm === "AM" && hour === 12) hour = 0
    
        let month = Months.indexOf(monthName)
    
        let utcDate = new Date(Date.UTC(year, month, day, hour, minute))
    
        let local = new Date(utcDate)
    
        let localDay = local.getDate()
        let localMonth = Months[local.getMonth()]
        let localYear = local.getFullYear()
        let localHour = local.getHours() % 12 || 12
        let localMinute = local.getMinutes().toString().padStart(2, '0')
        let localAmPm = local.getHours() >= 12 ? "PM" : "AM"
    
        return `${localDay} ${localMonth} ${localYear}, ${localHour}:${localMinute} ${localAmPm}`
    }

    return (
    <div className={styles.resultDashboard}>
        <div className={styles.dashBoardContent}>
            <div className={styles.dashboardHeader}>
                <p className={styles.dashboardTitle}>{lang["Inspection Results"][language]}</p>
                <p className={styles.dashboardSub}>{lang["Inspection ID"][language]} {id} {localTime(photowiseDetails["batch_hit_time"])}</p>
                <div>
                    <button onClick={()=>downloadPDF()} className={styles.downloadBtn}>{lang["Download PDF"][language]} <img src={downloadBtn} alt="Download" /></button>
                    {!Cookies.get("isMahindra") && <button onClick={()=>downloadJSON()} className={styles.downloadBtn}>{lang["Download JSON"][language]} <img src={downloadBtn} alt="Download" /></button>}
                    {!Cookies.get("isMahindra") && <button onClick={()=>downloadImages()} className={styles.downloadBtn}>{lang["Download Images"][language]} <img src={downloadBtn} alt="Download" /></button>}
                </div>
                {(Cookies.get("creationDate") && (editClients.includes(Cookies.get('master')) || new Date(Cookies.get("creationDate")) > new Date("Mon Feb 14 2025 00:00:00 GMT"))) && <div>
                    <button onClick={()=>disableEditing()} className={styles.downloadBtn}> {editMode ? lang["Submit"][language] :lang["Enable Editing"][language]} </button>
                    <button onClick={()=>generateNewPdf()} className={styles.downloadBtn}> {lang["Generate New PDF"][language]} </button>
                </div>}
                {Cookies.get('master') === "client_989" && <div style={{marginTop: "20px"}}>
                    <select value={stGobainId} onChange={(e)=>setStGobainId(e.target.value)}>
                        <option value= "">Select insurer</option>
                        {stGobainComp.map((item, index) => {
                        return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                    <button style={{margin: "0px"}} onClick={()=>downloadPersonaImages()} className={styles.downloadBtn}> {lang["Dowload Selected Images"][language]} </button>
                </div>}
            </div>
            <div className={styles.buttonSection}>
                {photowiseDetails["unified_json"]["additionalFeatures"]["fastTrackFlag"] && <button className={styles.dashboardButton}>{lang["Fast Track Claim"][language]}</button>}
                {(photowiseDetails["unified_json"]["claim"] !== {} && photowiseDetails["unified_json"]["claim"]['vehicleData']) && <button className={styles.dashboardButton}>{photowiseDetails["unified_json"]["claim"]['vehicleData']['impactType']}</button>}
                {(photowiseDetails["unified_json"]["incompleteUpload"] && photowiseDetails["unified_json"]["incompleteUpload"] === "Yes") && <button className={styles.dashboardButton}>{lang["Incomplete Upload"][language]}</button>}
                {(photowiseDetails["unified_json"]["preInspection"] !== {} && photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N") && <button className={styles.dashboardButton}>{lang["Recommended"][language]}</button>}
            </div>

        </div>
        <hr/>
        <div className={styles.dashboardTable} >
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Type of inspection"][language]}</p>
                    {photowiseDetails["web_app_case"] === "0"? <p>{lang["API/Portal Upload"][language]}</p>: <p>Super App</p>}
                </div>
            </div>
            {Object.keys(photowiseDetails["unified_json"]).includes("uploadStatus") ? <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Upload Status"][language]}</p>
                    {photowiseDetails["unified_json"]["uploadStatus"] === "complete upload" ? <p style={{color: "green"}}>{lang["Complete Upload"][language]}</p>: <p style={{color: "red"}}>{lang["Incomplete Upload"][language]}</p>}
                </div>
            </div> : null}
            <div>
                <div className={styles.dashboardTableBar}>
                    { editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditLicense(!editLicense)}></img>
                        <p>{lang["License Plate"][language]}</p>
                    </div> : <p>{lang["License Plate"][language]}</p>}
                    
                    {editLicense ? <div><input type='text' value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("licensePlate")}></img></div> :carLevelEditData["licensePlate"] ? <div style={{background: "none"}}> {(photowiseDetails["license_matched"] || octoClients.includes(Cookies.get("master"))) ? null : <span style={{color: "red"}}>{lang["License Mismatch"][language]}</span>} <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('licensePlate')) ? {color: "orange"}: {}}>{carLevelEditData["licensePlate"]}</p></div>: <p>----</p>}
                </div>
            </div>
            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Damage Severity Score"][language]}</p>
                    {/* {photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] ? <div style={{borderWidth: "4px", borderStyle: "solid", borderColor: `${severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}`, borderRadius: "50%", width: "45px", height: "45px"}}><p style={{padding: "5px"}}>{photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"]}</p></div>: <p>----</p>} */}
                    {Object.keys(photowiseDetails["unified_json"]).includes("totalLoss") ? 
                    <svg width="60" height="60" viewBox="-25 -25 250 250" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{transform: "rotate(-90deg)"}}>
                        <circle r="90" cx="100" cy="100" fill="transparent" stroke="#e0e0e0" stroke-width="20px" stroke-dasharray="565.48px" stroke-dashoffset="0"></circle>
                        <circle r="90" cx="100" cy="100" stroke={severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])} stroke-width="20px" stroke-linecap="round" stroke-dashoffset={getRadius()} fill="transparent" stroke-dasharray="565.2px"></circle>
                        <text x={photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] === 100 ? "59px" : photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"] >= 10 ? "73px": "86px"} y="117px" fill={severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])} font-size="52px" font-weight="bold" style={{transform:"rotate(90deg) translate(0px, -196px"}}>{Math.floor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}</text>
                    </svg>
                    : <p>----</p>}
                </div>
            </div>}
            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Repair Workflow"][language]}</p>
                    {(Object.keys(photowiseDetails["unified_json"]).includes("totalLoss") && Object.keys(photowiseDetails["unified_json"]["totalLoss"]).length >=3)  ? <p style={{color: `${severityColor(photowiseDetails["unified_json"]["totalLoss"]["totalLossScore"])}`}}>{photowiseDetails["unified_json"]["totalLoss"]["repairDecision"]}</p>: <p>----</p>}
                </div>
            </div>}
            { Cookies.get("client") === "agent_hiib_liberty_01" && <div>
                <div className={styles.dashboardTableBar}>
                    <p>Status Remark</p>
                    <p>{photowiseDetails["unified_json"]["preInspection"]["message"] ? photowiseDetails["unified_json"]["preInspection"]["message"] : "---"}</p>
                </div>
            </div>}
            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    { Cookies.get("isMahindra") ? "Model" : editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditMake(!editMake)}></img>
                        <p>{lang["Make"][language]}</p>
                    </div> : <p>{lang["Make"][language]}</p>}
                    
                    {Cookies.get("isMahindra") ? <p>{photowiseDetails["unified_json"]["vehicleReadings"]["model"]}</p> : editMake ? <div><input type='text' value={make} onChange={(e) => setMake(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("make")}></img></div> :carLevelEditData['make'] ? <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('make')) ? {color: "orange"} : {}}>{carLevelEditData['make']}</p>: <p>----</p>}
                </div>
            </div>}
            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    { Cookies.get("isMahindra") ? "Variant" : editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditModel(!editModel)}></img>
                        <p>{lang["Model"][language]}</p>
                    </div> : <p>{lang["Model"][language]}</p>}
                    
                    {Cookies.get("isMahindra") ? <p>{photowiseDetails["unified_json"]["vehicleReadings"]["subModel"]}</p> : editModel ? <div><input type='text' value={model} onChange={(e) => setModel(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("model")}></img></div> : carLevelEditData['model'] ? <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('model')) ? {color: "orange"} : {}}>{carLevelEditData['model']}</p>: <p>----</p>}
                </div>
            </div>}
            <div>
                <div className={styles.dashboardTableBar}>
                    {editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditOdometer(!editOdometer)}></img>
                        <p>{lang["Odometer"][language]}</p>
                    </div> : <p>{lang["Odometer"][language]}</p>}
                    
                    {editOdometer ? <div><input type='text' value={odometer} onChange={(e) => setOdometer(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("odometer")}></img></div> : carLevelEditData["odometer"] ? <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('odometer')) ? {color: "orange"} : {}}>{carLevelEditData["odometer"]}</p>: <p>----</p>}
                </div>
            </div>
            <div>
                <div className={styles.dashboardTableBar}>
                    {editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditVin(!editVin)}></img>
                        <p>{lang["VIN"][language]}</p>
                    </div> : <p>{lang["VIN"][language]}</p>}
                    
                    {editVin ? <div><input type='text' value={vin} onChange={(e) => setVin(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("vin")}></img></div> : (carLevelEditData["vin"] && carLevelEditData["vin"] !=="_________________") ? <div style={{background: "none"}}> {(photowiseDetails["vin_matched"] || octoClients.includes(Cookies.get("master"))) ? null : <span style={{color: "red"}}>({lang["VIN Mismatched"][language]})</span>} <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('vin')) ? {color: "orange"} : {}}>{carLevelEditData["vin"]}</p></div>: <p>----</p>}
                </div>
            </div>
            { !(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    {editMode ? <div>
                        <img style={{cursor: "pointer"}} src={editImg} alt="save" onClick={()=>setEditFuelmeter(!editFuelmeter)}></img>
                        <p>{lang["Fuel Meter"][language]}</p>
                    </div> : <p>{lang["Fuel Meter"][language]}</p>}
                    
                    {editFuelmeter ? <div><input type='text' value={fuelmeter} onChange={(e) => setFuelMeter(e.target.value)}></input> <img style={{cursor: "pointer"}} src={saveTick} alt="save" onClick={()=>updateEditData("fuelmeter")}></img></div> : carLevelEditData["fuelmeter"] ? <p style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes('fuelmeter')) ? {color: "orange"} : {}}>{carLevelEditData["fuelmeter"]}</p>: <p>----</p>}
                </div>
            </div>}

            {Object.keys(photowiseDetails["unified_json"]["customSection"]).includes("commercialVehicle") && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Commercial activity"][language]}</p>
                    <p style={{color: "red", fontWeight: 800}}>{lang[photowiseDetails["unified_json"]["customSection"]["commercialVehicle"]][language]}</p>
                </div>
            </div>}

            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Cause of Damage"][language]}</p>
                    {Object.keys(photowiseDetails["unified_json"]["claim"]).length !==0  ? <p>{photowiseDetails["unified_json"]["claim"]['vehicleData']['causeOfDamage']}</p>: <p>----</p>}
                </div>
            </div>}

            {!(octoClients.includes(Cookies.get("master"))) && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Glass Damage Region"][language]}</p>
                    {Object.keys(photowiseDetails["unified_json"]["additionalFeatures"]).includes("windshieldDamageRegion") ? photowiseDetails["unified_json"]["additionalFeatures"]["windshieldDamageRegion"] ? <p>{photowiseDetails["unified_json"]["additionalFeatures"]["windshieldDamageRegion"]}</p>: <p>----</p>: <p>----</p>}
                </div>
            </div>}
            { (Cookies.get("client") !== "admin_raheja_agent" && Cookies.get("master") !== "client_507" && Cookies.get("master") !== "client_1121" && Cookies.get("master") !== "client_1217") && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{lang["Approve Inspection"][language]}</p>
                    <div>
                        {Cookies.get('master') === "easyautotech" || window.top.location.host === "results.easydetect.ai" || Cookies.get('master') === "client_1124" || window.top.location.host === "inspection-vn.iglooinsure.com" || Cookies.get('master') === "client_1255" || Cookies.get('master') === "docksolution" ? 
                        <select value={agentApprove} onChange={(e)=>setAgentApprove(e.target.value)}>
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N" ? <option value="Approved">{lang["Approve"][language]} ({lang["Recommended"][language]})</option>: <option value="Approved">{lang["Approve"][language]}</option>}
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] === "N" ? <option value="Disapproved">{lang["Disapprove"][language]} ({lang["Recommended"][language]})</option>: <option value="Disapproved">{lang["Disapprove"][language]}</option>}
                        </select> : 
                        <select value={agentApprove} onChange={(e)=>setAgentApprove(e.target.value)}>
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] !== "N" ? <option value="Approved">{lang["Approve"][language]} ({lang["Recommended by Inspektlabs"][language]})</option>: <option value="Approved">{lang["Approve"][language]}</option>}
                            {photowiseDetails["unified_json"]["preInspection"]['recommendationStatus'] === "N" ? <option value="Disapproved">{lang["Disapprove"][language]} ({lang["Recommended by Inspektlabs"][language]})</option>: <option value="Disapproved">{lang["Disapprove"][language]}</option>}
                            {Cookies.get("master") === "claim_ydrogios" && <option value="Stand by">Αναμονή</option>}
                            {Cookies.get("master") === "claim_ydrogios" && <option value="Complete">Ολοκληρωμένηqa</option>}
                        </select>}
                        <button onClick={()=>{
                            if(Cookies.get("master") === "client_1257" || Cookies.get("master") === "client_1257_uat"){
                                if(agentApprove === "Disapproved"){
                                    setShowDissaproveModal(true)
                                }
                                else{
                                    sendApproval()
                                }
                            }
                            else{
                                sendApproval()
                            }
                        }} className={styles.submitBtn}>{lang["Submit"][language]}</button>
                    </div>
                </div>
            </div>}
            { (Cookies.get("client") !== "admin_raheja_agent" && Cookies.get("master") !== "client_507" && Cookies.get("master") !== "client_1121" && Cookies.get("master") !== "client_1217") && <div>
                <div className={styles.dashboardTableBar}>
                    <p>{Cookies.get('master') === "generali_demo" || Cookies.get('master') === "generali_internal" ? lang["Issue Policy"][language] : lang["Issue Report"][language]}</p>
                    <div>
                        <input
                        type="text"
                        placeholder={Cookies.get('master') === "generali_demo" || Cookies.get('master') === "generali_internal" ? "Policy Number" : lang["Report Number"][language]}
                        value={policyNumber}
                        ref={policyRef}
                        onChange={(e)=>{
                            policyRef.current.style.border="none"
                            setPolicyNumber(e.target.value)
                        }}
                        >
                        </input>
                        <button onClick={()=>{
                        if(!policyNumber){
                            policyRef.current.style.border="2px solid transparent"
                            policyRef.current.style.borderColor="red"
                            policyRef.current.style.transition="border-color 1s"
                            return
                        }
                        if(showApproveModal) return
                        setShowPolicyModal(true)
                        setPolicyEmail("")
                        sendPolicyApproval()}} className={styles.submitBtn}>{lang["Send"][language]}</button>
                    </div>
                </div>
            </div>}
            <div>
                <div className={styles.dashboardTableBar}>
                    <p>{Cookies.get("master") === "gipsa_inspekt" ? "TAT(min)" : lang["Remark"][language]}</p>
                    <div className={styles.remarkContainer} style={{background: "none"}}>
                        <p className={styles.remarkPara}>{photowiseDetails.remarks}</p>
                        <button onClick={()=>{
                            setShowCarRemarkModal(true)
                            if(photowiseDetails.remarks) setCarRemark(photowiseDetails.remarks)
                        }} className={styles.submitBtn}>{lang["Add"][language]}</button>
                    </div>
                </div>
            </div>
        </div>
        {showCarRemarkModal && <div className={styles.remarkModalContainer}>
            <div className={styles.remarkModal}>
                <h3>{lang["Add Remark"][language]}</h3>
                <textarea
                    value = {carRemark}
                    onChange={(e)=> setCarRemark(e.target.value)}
                    className={styles.remarkInput}
                >
                </textarea>
                <div className={styles.remarkModalButtonContainer}>
                    <div onClick={()=>sendRemarkData()}>{lang["Save"][language]}</div>
                    <div onClick={()=>{
                    setShowCarRemarkModal(false)
                    setCarRemark("")
                    }}>{lang["Close"][language]}</div>
                </div>
            </div>
        </div>}
        
        {showDissaproveModal && 
            <div className={styles.remarkModalContainer}>
                <div className={styles.remarkModal} style={{height: "140px", width: "500px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2em"}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                            <input
                            type='radio'
                            value='U/w reasons'
                            checked={disapprovedReason === "U/w reasons"}
                            onChange={()=>setDisapprovedReason("U/w reasons")}
                            />
                            <label htmlFor="U/w reasons">
                                U/w reasons
                            </label>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                            <input
                            type='radio'
                            value='Bad image quality'
                            checked={disapprovedReason === "Bad image quality"}
                            onChange={()=>setDisapprovedReason("Bad image quality")}
                            />
                            <label htmlFor="Bad image quality">
                                Bad image quality
                            </label>
                        </div>
                    </div>
                    <div className={styles.remarkModalButtonContainer}>
                        <div onClick={()=>sendApproval()}>{lang["Submit"][language]}</div>
                        <div onClick={()=>{
                            setDisapprovedReason("")
                            setShowDissaproveModal(false)}}>{lang["Close"][language]}</div>
                    </div>
                </div>
            </div>
        }

        {showApproveModal && 
            <div className={styles.polcyModalContainer}>
                <div style={{display: "flex"}} className={styles.policyModalMain}>
                    <img className={styles.tick} src={tick} alt="tick"></img>
                    <h3>{lang["submitted"][language]}</h3>
                </div>
            </div>
        }
        {showPolicyModal && 
        <div className={styles.polcyModalContainer}>
            { policyLoading === "none" ? <div style={{display: "flex"}} className={styles.policyModalMain}>
                <h3>{lang["Submit Policy"][language]}</h3>
                <input
                type='email'
                placeholder={lang["email"][language]}
                value={policyEmail}
                onChange={(e)=>setPolicyEmail(e.target.value)}
                ></input>
                <div className={styles.btnContainer}>
                    <button className={styles.submitBtn} onClick={()=> sendPolicyApproval()}>{lang["Submit"][language]}</button>
                    <button className={styles.submitBtn} onClick={()=> closePolicyModal()}>{lang["Close"][language]}</button>
                </div>
            </div>: 
            policyLoading === "loading" ? 
            <div style={{display: "flex"}} className={styles.policyModalMain}>
                <img className={styles.loading} src={modalLoading} alt="loading..."></img>
            </div> :
            policyLoading === "error" ? 
            <div style={{display: "flex"}} className={styles.policyModalMain}>
                <h4 style={{color: "red", textAlign: "center", marginTop: "30px"}}>{lang["error"][language]}</h4>
            </div>: 
            <div style={{display: "flex"}} className={styles.policyModalMain}>
                <img className={styles.tick} src={tick} alt="tick"></img>
                <h3>{lang["submitted"][language]}</h3>
            </div>}
        </div>}
    </div>  )
}

export default CarlevelResultDashboard