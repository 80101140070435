import React, { useState, useEffect, useContext } from 'react'
import profileImg from "../img/profile.png"
import filterimg from "../img/filter.png"
import car from "../img/carFleet.png"
import location from "../img/location.png"
import logo from "../img/logoBlack.png"
import downArrow from '../img/downArrowFleet.png'
import styles from './style/FleetInspection.module.scss'
import loadingImg from '../img/loading.gif'
import { makeAuthRequest, checkCookies, removeCookies } from './context/utils'
import conf from '../config/config.json'
import Cookies from 'js-cookie'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from './context';
import Navbar from './Navbar'
import cross from "../img/cross.png"
import Multiselect from 'multiselect-react-dropdown'
import IncrementalVehicleListControlBar from './IncrementalVehicleListControlBar'

const IncrementalVehicleList = () => {
  
  const { incrementalSearchInspection, setIncrementalSearchInspection, incrementalSearchDate, setIncrementalSearchDate, incrementalSearchCarCondtion, setIncrementalSearchCarCondtion, incrementalInspectionProcessingData, setIncrementalInspectionProcessingData, incrementalInspectionData, setIncrementalInspectionData,
    incrementalInspectionAvailableData, setIncrementalInspectionAvailableData, incrementalInspectionRejectedData, setIncrementalInspectionRejectedData, incrementalInspectionLoading, setIncrementalInspectionLoading } = useContext(AppContext)
  
  const [processingCount, setProcessingCount] = useState(0)
  const [availableCount, setAvailableCount] = useState(0)
  const [rejectedCount, setRejectedCount] = useState(0)

  const {urlLink} = conf
  const {id} = useParams()
  const navigate = useNavigate()

  const getData = async () => {
    setIncrementalInspectionLoading(true)
    if(!id) return navigate("/incremental-damage")
    const res = await makeAuthRequest(`${urlLink}incremental-fleet-inspections`, { tl_id: Cookies.get("tlId"), client_type: Cookies.get("clientType"), client_id: Cookies.get("client"), license: id, agent_id: ""})
    const data1 = await res.json()
    if(res.ok){
        setIncrementalInspectionData(data1)
        setIncrementalInspectionProcessingData(data1.processing)
        setIncrementalInspectionAvailableData(data1.available)
        setIncrementalInspectionRejectedData(data1.rejected)
        setIncrementalInspectionLoading(false)
    }
  }

  const localTime = (time) => {
    let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    let [datePart, timePart] = time.split(", ")
    let [day, monthName, year] = datePart.split(" ")
    let [timeValue, ampm] = timePart.split(" ")
    
    let [hour, minute] = timeValue.split(":").map(Number)
    if (ampm === "PM" && hour !== 12) hour += 12
    if (ampm === "AM" && hour === 12) hour = 0

    let month = Months.indexOf(monthName)

    let utcDate = new Date(Date.UTC(year, month, day, hour, minute))

    let local = new Date(utcDate)

    let localDay = local.getDate()
    let localMonth = Months[local.getMonth()]
    let localYear = local.getFullYear()
    let localHour = local.getHours() % 12 || 12
    let localMinute = local.getMinutes().toString().padStart(2, '0')
    let localAmPm = local.getHours() >= 12 ? "PM" : "AM"

    return `${localDay} ${localMonth} ${localYear}, ${localHour}:${localMinute} ${localAmPm}`
  }

  const vehicleAge = (date) => {

    let months = Math.max(
      (new Date().getFullYear() - new Date(date).getFullYear()) * 12 +
      new Date().getMonth() -
      new Date(date).getMonth(),
      0
    )
    
    if(months > 12){
      return Math.floor(months / 12) === 1 ? `${Math.floor(months / 12)} year` : `${Math.floor(months / 12)} years`
    }
    else return months === 1 ? `${months} month` : `${months} months`
  }

  useEffect(()=>{
    if(!checkCookies()){
        removeCookies()
        return navigate("/login")
    }
    getData()
  },[])

  return (
    <>
    <Navbar/>
    <IncrementalVehicleListControlBar/>
    {incrementalInspectionLoading ? 
    <div className={styles.loading} >
        <img src={loadingImg} alt='loading...'/>
    </div>: 
    <div className={styles.container}>
      <div className={styles.info}>
        <img className={styles.infoimg} src={incrementalInspectionData.incremental_damage_record[0].car_image ? incrementalInspectionData.incremental_damage_record[0].car_image : car} alt='car'></img>
        <div className={styles.infoContent}>
            <div className={styles.headingContent}>
                <p style={{fontWeight: 600}}>License: {incrementalInspectionData.incremental_damage_record[0].license}</p>
                <p>{incrementalInspectionData.incremental_damage_record[0].last_inspection_date ? localTime(incrementalInspectionData.incremental_damage_record[0].last_inspection_date).split(", ")[0] : "---"}</p>
                <p>{incrementalInspectionData.incremental_damage_record[0].last_inspection_date ? localTime(incrementalInspectionData.incremental_damage_record[0].last_inspection_date).split(", ")[1] : "---"}</p>
            </div>
            <div className={styles.allData}>
                <div className={styles.address} style={{flexShrink: 0}}>
                    <span>{incrementalInspectionData.incremental_damage_record[0].make ? incrementalInspectionData.incremental_damage_record[0].make : "---"}, {incrementalInspectionData.incremental_damage_record[0].model ? incrementalInspectionData.incremental_damage_record[0].model : "---"}</span>
                    <div>
                        <span>VIN: {incrementalInspectionData.incremental_damage_record[0].vin ? incrementalInspectionData.incremental_damage_record[0].vin : "---"}</span>
                    </div>
                </div>
                <div className={styles.carGrade}>
                    <span>Car Condition: {incrementalInspectionData.incremental_damage_record[0].car_condition ? incrementalInspectionData.incremental_damage_record[0].car_condition : "---"}</span>
                    <span>{incrementalInspectionData.incremental_damage_record[0].status}</span>
                </div>
                <div className={styles.kms}>
                    <span style={{visibility: "hidden"}}>2000 KM</span>
                    <span>{incrementalInspectionData.incremental_damage_record[0].odo ? incrementalInspectionData.incremental_damage_record[0].odo + " KMs" : "---"}</span>
                </div>
                <div className={styles.age}>
                    <div className={styles.ageTitle}>
                        <span>Repair Workflow</span>
                        <span>Severity</span>
                    </div>
                    <div className={styles.ageValues}>
                        <span>{incrementalInspectionData.incremental_damage_record[0].repair_workflow ? incrementalInspectionData.incremental_damage_record[0].repair_workflow : "---"}</span>
                        <span>{incrementalInspectionData.incremental_damage_record[0].severity ? incrementalInspectionData.incremental_damage_record[0].severity : "---"}</span>
                    </div>
                </div>
            </div>
        </div>    
      </div>

      <div className={styles.card}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Available ({incrementalInspectionAvailableData.length})</p>
            </div>
            {incrementalInspectionAvailableData.map((item, index) => {
                if(index >= 2 + availableCount) return
                return (
                    <div className={styles.carData} style={{cursor: "pointer", gap: "5em"}} key={index} onClick={()=>{return navigate(`/pre-inspection/${item.inspection_id}`)}}>
                        <p className={styles.name} style={{width: "225px"}}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time} style={{width: "175px"}}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time} style={{width: "85px"}}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {availableCount !== 0 && <div className={styles.minButton} onClick={()=>setAvailableCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {incrementalInspectionAvailableData.length > availableCount + 2 && <div className={styles.allButton} onClick={()=>setAvailableCount(availableCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>

      <div className={styles.card}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Processing ({incrementalInspectionProcessingData.length})</p>
            </div>
            {incrementalInspectionProcessingData.map((item, index) => {
                if(index >= 2 + processingCount) return
                return (
                    <div className={styles.carData} key={index}>
                        <p className={styles.name}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {processingCount !== 0 && <div className={styles.minButton} onClick={()=>setProcessingCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {incrementalInspectionProcessingData.length > processingCount + 2 && <div className={styles.allButton} onClick={()=>setProcessingCount(processingCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>

        <div className={styles.card} style={{marginBottom: "15%"}}>
            <div className={styles.cardHeading}>
                <p className={styles.heading1}> Rejected ({incrementalInspectionRejectedData.length})</p>
            </div>
            {incrementalInspectionRejectedData.map((item, index) => {
                if(index >= 2 + rejectedCount) return
                return (
                    <div className={styles.carData} key={index} onClick={()=> { return navigate(`/rejected/${item.inspection_id}`)}}>
                        <p className={styles.name}>{item.inspection_id ? item.inspection_id : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[0] : "---"}</p>
                        <p className={styles.time}>{item.inspection_date ? localTime(item.inspection_date).split(", ")[1] : "---"}</p>
                        <p className={styles.odo}>{item.odo ? item.odo + " KMs" : "---"}</p>
                    </div>
                )
            })}
            <div className={styles.buttonContainer}>
                {rejectedCount !== 0 && <div className={styles.minButton} onClick={()=>setRejectedCount(0)}>
                    <p>Minimize Inspection</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
                {incrementalInspectionRejectedData.length > rejectedCount + 2 && <div className={styles.allButton} onClick={()=>setRejectedCount(rejectedCount + 10)}>
                    <p>View all inspections</p>
                    <img src={downArrow} alt="arrow"></img>
                </div>}
            </div>
        </div>
    </div>}
    </>
  );
}

export default IncrementalVehicleList