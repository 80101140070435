import React,{ useContext, useState } from 'react'
import styles from './style/PartlevelResultDashboad.module.scss'
import downloadBtn from '../img/download.png'
import DamageTypeModal from './DamageTypeModal';
import { AppContext } from './context';
import { useParams } from 'react-router-dom';
import { makeAuthRequest } from './context/utils';
import Cookies from 'js-cookie';
import lang from './language/PartLevelDashboard.json'
import partDamage from './language/partDamage.json'
import config from '../config/config.json'
import addImg from '../img/addRow.png'
import delImg from '../img/deleteRow.png'
import editImg from '../img/Edit1.png'
import Multiselect from 'multiselect-react-dropdown'
import nextBtn from '../img/preInspection/blueNext.png'
import greyNextBtn from '../img/preInspection/greyNext.png'

const PartlevelDashboad = () => {
    const {urlLink, partEditList, damageEditList, editClients, stGobainComp} = config

    const {photowiseDetails, language, editMode, setEditMode, setPartLevelEditData, partLevelEditData, partCheckBox, open, setOpen, 
           setPartCheckBox, editRow, setEditRow, partLevelDisplayData, setPartLevelDisplayData, carLevelEditData, isGTEstimateActive, editedData, 
           setEditedData, personaList, allowDamageModal, setSelectedImage} = useContext(AppContext)

    const [count, setCount] = useState(0)
    const [stGobainId, setStGobainId] = useState("")

    const { id } = useParams()
    const onOpenModal = (value) => {
        if(editMode) return
        if(!allowDamageModal) return
        setOpen(true)
    }
    const downloadPDF = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/report`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), language}) : await makeAuthRequest(`${urlLink}download/report`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), language})
        
        if(res.ok){
            const data = await res.json()
            window.open(data.url, "_blank")
        }
    }

    const downloadImages = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/images`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName"), image_type: "original"}) : await makeAuthRequest(`${urlLink}download/images`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original"})
        
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const downloadJSON = async() => {
        const res = Cookies.get("isMahindra") ? await makeAuthRequest(`${urlLink}download/json`, { client_id: Cookies.get('client'), inspection_id: id, access_token: Cookies.get("token"), tl_id: Cookies.get("MahindraTlId"), client_type: Cookies.get("MahindraClientType"), tl_name: Cookies.get("MahindraTlName"), agent_id : Cookies.get("MahindraUserId"), agent_name: Cookies.get("MahindraUserName")}) : await makeAuthRequest(`${urlLink}download/json`, {client_id: Cookies.get('client'), inspection_id: id, client_type: Cookies.get('clientType')})
        
        if(res.ok){
            const data = await res.json()
            if(data.status === "failed") return alert(data.url)
            window.open(data.url, "_blank")
        }
    }

    const generateNewPdf = async () => {
        const res = await makeAuthRequest(`${urlLink}generatePDF`, {inspection_id: id, client_type: Cookies.get('clientType')})
        window.alert(lang["pdfGenMsg"][language])
    }

    const downloadPersonaImages = async() => {
        if(!stGobainId){
            return alert("Please select an insurer")
        }
        const res = await makeAuthRequest(`${urlLink}download/images/persona`, {client_id: Cookies.get('master'), inspection_id: id, client_type: Cookies.get('clientType'), image_type: "original", insurer_name: stGobainId})
        if(res.ok){
            const data = await res.json()
            if (data.url == null){
                return
            }else{
                window.open(data.url, "_blank")
            }
        }
    }

    const submitEditData = async() => {
        let payload = {
            "inspection_id": id,
            "client_id": Cookies.get('client'),
            "license_plate": carLevelEditData["licensePlate"],
            "make": carLevelEditData["make"],
            "model": carLevelEditData["model"],
            "odometer": carLevelEditData["odometer"],
            "vin": carLevelEditData["vin"],
            "fuel_meter": carLevelEditData["fuelmeter"],
            "cause_of_damage": "",
            "remark": "",
            "damages": partLevelDisplayData,
            "gtMotive": isGTEstimateActive ? "True" : "False",
            "edits": {"data": editedData}
        }

        if(validateData(payload)){
            if(payload["odometer"] !== ""){
                payload["odometer"] = parseFloat(payload["odometer"])
            }
            if(payload["fuel_meter"] !== ""){
                payload["fuel_meter"] = parseFloat(payload["fuel_meter"])
            }
            for (let i = 0; i < payload["damages"].length; i++) {
                payload["damages"].damageSeverityScore = parseFloat(payload["damages"].damageSeverityScore)
                payload["damages"].laborRepairUnits = parseFloat(payload["damages"].laborRepairUnits)
            }
        }else{
            return
        }
        
        console.log(payload)
        const res = await makeAuthRequest(`${urlLink}editInspection`, payload)
        
        if(res.ok){
            const data = await res.json()
            setEditMode(false)
            console.log(data)
        }
    }

    const validateData = (payload) => {

        if(payload["odometer"] !== ""){
            if(!(/^\d+$/.test(payload["odometer"])) || payload["odometer"].length >= 10 || parseFloat(payload["odometer"]) < 0){
                alert(lang["odoErrorMsg"][language])
                return false
            }
        }
        
        if(payload["fuel_meter"] !== ""){
            if(!(/^\d+$/.test(payload["fuel_meter"])) || payload["fuel_meter"].length >= 10 || parseFloat(payload["fuel_meter"]) < 0){
                alert(lang["fuelErrorMsg"][language])
                return false
            }
        }

        if(payload["vin"] !== ""){
            if(payload["vin"].length > 17){
                alert(lang["vinErrorMsg"][language])
                return false
            }
        }

        for (let i = 0; i < payload["damages"].length; i++) {

            if(payload["damages"][i].listOfDamages === ""){
                alert("Damage missing in row" + (i+1))
                return false
            }
    
            if(payload["damages"][i].laborRepairUnits === ""){
                alert(lang["LbrhoursEmptyError"][language])
                return false
            }

            if(payload["damages"][i].damageSeverityScore === ""){
                alert(lang["sevScoreEmptyerror"][language])
                return false
            }

            if(parseFloat(payload["damages"][i].damageSeverityScore) < 0 || parseFloat(payload["damages"][i].damageSeverityScore) > 1 || payload["damages"][i].damageSeverityScore.length >= 5){
                alert(lang["sevScoreError"][language])
                return false
            }

            if(parseFloat(payload["damages"][i].laborRepairUnits) < 0 || payload["damages"][i].laborRepairUnits.length >= 9){
                alert(lang["lbrHourserror"][language])
                return false
            }

        }
        return true
    }

    const convertDamage = (damages) => {
        let temp = []
        let nameFound = false
        if(Array.isArray(damages)){
            damages = damages.join(', ')
        }
        if(!damages.split('').includes(",")){
            for(let i = 0; i <partDamage.length; i++){
                if(partDamage[i].English === damages){
                    if(Object.keys(partDamage[i]).includes(language)){
                        nameFound = true
                        return partDamage[i][language]
                    }
                }
            }
            if(!nameFound) return damages
        }else{
            let damageList = damages.split(', ')
            for(let i = 0; i < damageList.length; i++){
                nameFound = false
                for(let j = 0; j <partDamage.length; j++){
                    if(partDamage[j].English === damageList[i]){
                        if(Object.keys(partDamage[j]).includes(language)){
                            nameFound = true
                            temp.push(partDamage[j][language])
                            break
                        }
                    }
                }
                if(!nameFound) temp.push(damageList[i])
            }
            return temp.join(', ')
        }
    }

    const convertPart = (part) => {
        let nameFound = false
        for(let i = 0; i <partDamage.length; i++){
            if(partDamage[i].English === part){
                if(Object.keys(partDamage[i]).includes(language)){
                    nameFound = true
                    return partDamage[i][language]
                }
            }
        }
        if(!nameFound) return part
    }

    const disableEditing = () => {
        if(editMode){
            setEditRow(false)
            let temp = partCheckBox
            for (let i = 0; i < partCheckBox.length; i++) {
                if(partCheckBox[i] === "true"){
                    temp[i] = "false"
                }
            }
            setPartCheckBox(temp)
            let answer = confirm(lang["confirmMsg"][language])
            if(answer){
                submitEditData()
            }
            else{
                return
            }
        }else{
            setEditMode(true)
        }
    }

    const handleCheckboxChange = (position) => {
        const updatedCheckedState = partCheckBox.map((item, index) => {
            if(index === position){
                if(partCheckBox[position] === "false"){
                    return "true"
                }
                if(partCheckBox[position] === "true"){
                    return "false"
                }
            }
            else{
                return item
            }
        })
        setPartCheckBox(updatedCheckedState)
    }

    const updatePart = (position, value) => {
        if(partLevelDisplayData[position]['partName'] != value){
            let temp_obj = editedData
            temp_obj.push(`row_${position}_part`)
            setEditedData(temp_obj)
        }
        let temp = partLevelDisplayData
        temp[position]["partName"] = value
        setPartLevelDisplayData(temp)
        setCount(count+1)
    }

    const updateDamage = (position, values) => {
        if(partLevelDisplayData[position]['listOfDamages'] != values.join(', ')){
            let temp_obj = editedData
            temp_obj.push(`row_${position}_damage`)
            setEditedData(temp_obj)
        }
        let temp = partLevelDisplayData
        temp[position]["listOfDamages"] = values.join(', ')
        setPartLevelDisplayData(temp)
        setCount(count+1)
    }

    const updateDamageSeverity = (position, value) => {
        if(partLevelDisplayData[position]['damageSeverityScore'] != value){
            let temp_obj = editedData
            temp_obj.push(`row_${position}_damageSeverityScore`)
            setEditedData(temp_obj)
        }
        let temp = partLevelDisplayData
        temp[position]["damageSeverityScore"] = parseFloat(value)
        setPartLevelDisplayData(temp)
        setCount(count+1)
    }

    const updateOperation = (position, value) => {
        if(partLevelDisplayData[position]['laborOperation'] != value){
            let temp_obj = editedData
            temp_obj.push(`row_${position}_laborOperation`)
            setEditedData(temp_obj)
        }
        let temp = partLevelDisplayData
        temp[position]["laborOperation"] = value
        setPartLevelDisplayData(temp)
        setCount(count+1)
    }

    const updateHours = (position, value) => {
        if(partLevelDisplayData[position]['laborRepairUnits'] != value){
            let temp_obj = editedData
            temp_obj.push(`row_${position}_laborRepairUnits`)
            setEditedData(temp_obj)
        }
        let temp = partLevelDisplayData
        temp[position]["laborRepairUnits"] = parseFloat(value)
        setPartLevelDisplayData(temp)
        setCount(count+1)
    }

    const deleteRow = () => {
        if(!partCheckBox.includes("true")) return

        let selectedIndex = []
        let tempCheckBox = partCheckBox
        let tempDisplayData = partLevelDisplayData
        let tempEditedData = editedData
        let removedData=[]
        let addedData=[]

        for (let i = 0; i < partCheckBox.length; i++) {
            if(partCheckBox[i] === "true"){
                selectedIndex.push(i)
            }
        }

        for (let i = selectedIndex.length -1; i >= 0; i--){
            for(let j = 0; j < editedData.length; j++){
                if(editedData[j].split('_')[0] == "row"){
                    if(editedData[j].split('_')[1] == selectedIndex[i]){
                        removedData.push(editedData[j])
                    }
                    if(editedData[j].split('_')[1] > selectedIndex[i]){
                        removedData.push(editedData[j])
                        let temp = editedData[j].split('_')
                        temp[1] = temp[1] - 1
                        addedData.push(temp.join('_'))
                    }
                }
            }
        }

        for(let i = 0; i < tempEditedData.length; i++){
            for(let j = 0; j < removedData.length; j++){
                if(tempEditedData[i] == removedData[j]){
                    tempEditedData[i] = ""
                }
            }
        }

        for (let i = selectedIndex.length -1; i >= 0; i--){
            tempCheckBox.splice(selectedIndex[i],1);
            tempDisplayData.splice(selectedIndex[i],1);
        }

        setPartLevelDisplayData(tempDisplayData)
        setPartCheckBox(tempCheckBox)
        setEditedData([...tempEditedData, ...addedData])
        setCount(count+1)
    }

    const deleteTable = () => {
        let answer = confirm(lang["deleteTableMsg"][language])
        if(answer){
            setPartLevelDisplayData([])
            setPartCheckBox([])
            setCount([])
            setEditedData([])
        }else{
            return
        }
    }

    const addRow = () => {

        let newRow = {
            "confidenceScore": 0,
            "damageSeverityScore": 0,
            "laborOperation": "Repair",
            "laborRepairUnits": 0,
            "listOfDamages": "scratch_or_spot",
            "paintLaborUnits": 0,
            "partName": "Front Bumper",
            "removalRefitUnits": 0.0
        }

        let tempCheckBox = partCheckBox
        let tempDisplayData = partLevelDisplayData

        tempCheckBox.push("true")
        tempDisplayData.push(newRow)

        setPartLevelDisplayData(tempDisplayData)
        setPartCheckBox(tempCheckBox)
        setCount(count+1)

    }

    const checkImage = (partName) => {

        let partExists = photowiseDetails["relevant_image_section"].some(obj => 
            Array.isArray(obj.boundingBoxInformation) && 
            obj.boundingBoxInformation.some(info => info.partComponent === partName)
        )

        if(partExists){
            let index = photowiseDetails["relevant_image_section"].findIndex(obj => 
                Array.isArray(obj.boundingBoxInformation) && 
                obj.boundingBoxInformation.some(info => info.partComponent === partName)
            )
            setSelectedImage(photowiseDetails["relevant_image_section"][index])
        }
    }

    const localTime = (time) => {
        let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        let [datePart, timePart] = time.split(", ")
        let [day, monthName, year] = datePart.split(" ")
        let [timeValue, ampm] = timePart.split(" ")
        
        let [hour, minute] = timeValue.split(":").map(Number)
        if (ampm === "PM" && hour !== 12) hour += 12
        if (ampm === "AM" && hour === 12) hour = 0
    
        let month = Months.indexOf(monthName)
    
        let utcDate = new Date(Date.UTC(year, month, day, hour, minute))
    
        let local = new Date(utcDate)
    
        let localDay = local.getDate()
        let localMonth = Months[local.getMonth()]
        let localYear = local.getFullYear()
        let localHour = local.getHours() % 12 || 12
        let localMinute = local.getMinutes().toString().padStart(2, '0')
        let localAmPm = local.getHours() >= 12 ? "PM" : "AM"
    
        return `${localDay} ${localMonth} ${localYear}, ${localHour}:${localMinute} ${localAmPm}`
    }

    return (
    <div className={styles.partLevelDashboard}>
        <div className={styles.partLevelDashboardContent}>
            <div className={styles.partLevelDashboardHeader}>
                <p className={styles.dashboardTitle}>{lang["Inspection Results"][language]}</p>
                <p className={styles.dashboardSub}>{lang["Inspection ID"][language]} {id} {localTime(photowiseDetails["batch_hit_time"])}</p>
                <div>
                    <button onClick={()=>downloadPDF()} className={styles.downloadBtn}>{lang["Download PDF"][language]} <img src={downloadBtn} alt="Download" /></button>
                    {!Cookies.get("isMahindra") && <button onClick={()=>downloadJSON()} className={styles.downloadBtn}>{lang["Download JSON"][language]} <img src={downloadBtn} alt="Download" /></button>}
                    {!Cookies.get("isMahindra") && <button onClick={()=>downloadImages()} className={styles.downloadBtn}>{lang["Download Images"][language]} <img src={downloadBtn} alt="Download" /></button>}
                </div>
                {(Cookies.get("creationDate") && (editClients.includes(Cookies.get('master')) || new Date(Cookies.get("creationDate")) > new Date("Mon Feb 14 2025 00:00:00 GMT"))) && <div>
                    <button onClick={()=>disableEditing()} className={styles.downloadBtn}> {editMode ? lang["Submit"][language] :lang["Enable Editing"][language]} </button>
                    <button onClick={()=>generateNewPdf()} className={styles.downloadBtn}> {lang["Generate New PDF"][language]} </button>
                </div>}
                {Cookies.get('master') === "client_989" && <div style={{marginTop: "20px"}}>
                    <select value={stGobainId} onChange={(e)=>setStGobainId(e.target.value)}>
                        <option value= "">Select insurer</option>
                        {stGobainComp.map((item, index) => {
                        return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                    <button style={{margin: "0px"}} onClick={()=>downloadPersonaImages()} className={styles.downloadBtn}> {lang["Dowload Selected Images"][language]} </button>
                </div>}
            </div>
            {/* <div className={styles.DashboardCircle}>
                <p className={styles.footerTitle}>Demage Index</p>
                <div className={styles.footermark}>
                    <div className={styles.circle1}></div><p>Low</p>
                    <div className={styles.circle2}></div><p>Medium</p>
                    <div className={styles.circle3}></div><p>High</p>
                </div>
            </div> */}
            {editMode && <div className={styles.editOptions}>
                <div onClick={()=>addRow()}>
                    <img src={addImg} alt=""></img>
                    <p>{lang["Add Row"][language]}</p>
                </div>
                <div onClick={()=>{partCheckBox.includes("true") && setEditRow(!editRow)}} style={partCheckBox.includes("true") ? {opacity: 1} : {opacity: 0.5}}>
                    <img src={editImg} alt=""></img>
                    <p>{lang["Edit Row"][language]}</p>
                </div>
                <div onClick={()=>deleteRow()} style={partCheckBox.includes("true") ? {opacity: 1} : {opacity: 0.5}}>
                    <img src={delImg} alt=""></img>
                    <p>{lang["Delete Row"][language]}</p>
                </div>
                <div onClick={()=>deleteTable()}>
                    <img src={delImg} alt=""></img>
                    <p>{lang["Delete Table"][language]}</p>
                </div>
            </div>}
            <div className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            {editMode ? <td>  </td> : null}
                            <td>{lang["Part"][language]}</td>
                            <td>{lang["Damage Type"][language]}</td>
                            <td>{lang["Confidence Score"][language]}</td>
                            <td>{lang["Severity"][language]}</td>
                            {Cookies.get('master') !== "client_507" && <td>{lang["Operation"][language]}</td>}
                            {Cookies.get('master') !== "client_507" && <td>{lang["Labour Hrs."][language]}</td>}
                            {editMode ? null : <td></td>}
                        </tr>
                    </thead>
                    <tbody>
                    {partLevelDisplayData.map((v,i) => <tr key={i}>
                    {editMode ? <td> <input style={{width: "40px"}} type='checkbox' checked={partCheckBox[i] === "false" ? false : true} onChange={() => handleCheckboxChange(i)}></input> </td> : null}
                    {(editRow && partCheckBox[i] === "true") ? 
                    <td>
                        <Multiselect
                            isObject={false}
                            customCloseIcon={<></>}
                            onSearch={()=>{}}
                            onSelect={(newList,item)=>updatePart(i, newList[0])}
                            selectedValues={[partLevelDisplayData[i]["partName"]]}
                            options={partEditList}
                            style={{
                                searchBox: {
                                    'border': 'none',
                                    'border-bottom': '1px solid black',
                                    'border-radius': '0px',
                                    'padding': '0px'
                                },

                                chips: {
                                    'font-size': '12px',
                                    'margin': '0px',
                                    'white-space': 'normal',
                                    'color': '#010101'
                                },

                                multiselectContainer: {
                                    'font-size': '11px',
                                    'word-wrap': 'break-word'
                                }
                            }}
                            singleSelect
                        />
                    </td>
                    : <td style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes(`row_${i}_part`)) ? {color: "orange", cursor: "pointer"}: {color: "#157aff", cursor: "pointer"}} onClick={()=>checkImage(v.partName)}>{convertPart(v.partName)}</td>}

                    {(editRow && partCheckBox[i] === "true") ? 
                    <td>
                        <Multiselect
                            isObject={false}
                            onRemove={(newList,item)=>updateDamage(i, newList)}
                            onSearch={()=>{}}
                            onSelect={(newList,item)=>updateDamage(i, newList)}
                            selectedValues={Array.isArray(partLevelDisplayData[i]["listOfDamages"]) ? partLevelDisplayData[i]["listOfDamages"] : partLevelDisplayData[i]["listOfDamages"] ? partLevelDisplayData[i]["listOfDamages"].split(', ') : []}
                            options={damageEditList}
                            style={{
                                chips: {
                                    'font-size': '10px',
                                    'white-space': 'normal',
                                },

                                multiselectContainer: {
                                    'font-size': '11px',
                                    'word-wrap': 'break-word'
                                }
                            }}
                        />
                    </td>
                    : <td style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes(`row_${i}_damage`)) ? {color: "orange"}: {}}>{convertDamage(v.listOfDamages)}</td>}


                    <td>{`${Math.floor(v.confidenceScore * 100)}%`}</td>

                    {(editRow && partCheckBox[i] === "true") ? 
                    <td>
                        <input type='number' value={partLevelDisplayData[i]["damageSeverityScore"]} onChange={(e)=>updateDamageSeverity(i, e.target.value)}></input>
                    </td>
                    : <td style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes(`row_${i}_damageSeverityScore`)) ? {color: "orange"}: {}}>{`${Math.floor(v.damageSeverityScore * 100)}%`}</td>}
                    
                    {Cookies.get('master') !== "client_507" ? 
                    (editRow && partCheckBox[i] === "true") ? 
                    <td>
                        <Multiselect
                            isObject={false}
                            customCloseIcon={<></>}
                            onSearch={()=>{}}
                            onSelect={(newList,item)=>updateOperation(i, newList[0])}
                            selectedValues={[partLevelDisplayData[i]["laborOperation"]]}
                            options={["fuse_uat", "fuse_prod"].includes(Cookies.get("master")) ? ["Repair", "Replace", "No Action", "S/R"] : ["Repair", "Replace", "No Action", "R/R"]}
                            style={{
                                searchBox: {
                                    'border': 'none',
                                    'border-bottom': '1px solid black',
                                    'border-radius': '0px',
                                    'padding': '0px'
                                },

                                chips: {
                                    'font-size': '12px',
                                    'margin': '0px',
                                    'white-space': 'normal',
                                    'color': '#010101'
                                },

                                multiselectContainer: {
                                    'font-size': '11px',
                                    'word-wrap': 'break-word'
                                }
                            }}
                            singleSelect
                        />
                    </td>
                    : <td style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes(`row_${i}_laborOperation`)) ? {color: "orange"}: {}}>{(Object.keys(lang).includes(v.laborOperation) && Object.keys(lang[v.laborOperation]).includes(language)) ? lang[v.laborOperation][language] : v.laborOperation}</td>:null}

                    {Cookies.get('master') !== "client_507" ? 
                    (editRow && partCheckBox[i] === "true") ? 
                    <td>
                        <input type='number' value={partLevelDisplayData[i]["laborRepairUnits"]} onChange={(e)=>updateHours(i, e.target.value)}></input>
                    </td>
                    : <td style={(photowiseDetails["edits"]["edits"] && JSON.parse(photowiseDetails["edits"]["edits"]).data.includes(`row_${i}_laborRepairUnits`)) ? {color: "orange"}: {}}>{v.laborRepairUnits}</td>:null}

                    {!editMode && <td><div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><img style={allowDamageModal ? {cursor: "pointer"} : {cursor: "default"}} onClick={()=>{onOpenModal(i)}} src={allowDamageModal ? nextBtn : greyNextBtn}></img><img style={allowDamageModal ? {cursor: "pointer"} : {cursor: "default"}} onClick={()=>{onOpenModal(i)}} src={allowDamageModal ? nextBtn : greyNextBtn}></img></div></td>}
                    
                    </tr>)}
                    </tbody>
                </table>
            </div>
            {open && 
                <div className={styles.hailDamageModal}>
                    <DamageTypeModal />
                </div>}
        </div>
    </div>
  )
}

export default PartlevelDashboad